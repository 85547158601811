import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerParametros() {
    var url = ConfigAPI.baseURL + "parameters";

    return instance.get(url);
  },
  obtener() {
    var url = ConfigAPI.baseURL + "permissions" + Session.getToken();
    return instance.get(url);
  },
  configurar(data) {
    var params = data

    var url = ConfigAPI.baseURL + "permissions/configurate" + Session.getToken();
    return instance.post(url,params);
  },
  configurarWidget(data) {
    var params = data

    var url = ConfigAPI.baseURL + "widget/configurate" + Session.getToken();
    return instance.post(url,params);
  },
  configurarRoleDeny(data) {
    var params = data

    var url = ConfigAPI.baseURL + "permissions/elements/configurate" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerRoles() {
    var url = ConfigAPI.baseURL + "roles" + Session.getToken();
    return instance.get(url);
  },
  configurarParametro(data) {
    var params = data

    var url = ConfigAPI.baseURL + "parameters/configurate" + Session.getToken();
    return instance.post(url,params);
  },
}

export default servicesAPI;
