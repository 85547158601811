<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="5">
            <h4 id="traffic" class="card-title mb-0">Configuración</h4>
            <div class="small text-muted">Configuración de permisos de visualización y acción por rol para los módulos activos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"
                    v-if="table.items.length">

                <template v-slot:row-details="row">
                   <b-card no-body>
                    <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-info"
                            content-class="mt-3">

                      <b-tab title="Vistas" active>
                        <b-card-text>
                          <b-tabs pills card vertical>

                            <b-tab title="Staff" active v-if="isModuleActive('personal')">
                              <b-card-text>

                                <b-list-group>
                                  <b-list-group-item href="#"
                                                    class="flex-column align-items-start"
                                                    v-for="item in filtroPersonal(row.item.modules_permissions)"
                                                    :key="item.id">
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">
                                        ID:{{item.id}} - {{item.reference}}
                                      </h5>
                                      <b-badge variant="success" class="badge-module-permission-init-custom pull-right" v-if="item.init">Pantalla principal</b-badge>
                                    </div>

                                    <small class="text-muted">Roles que pueden visualizar la vista</small>
                                    &nbsp;&nbsp;<b-button variant="outline-info" size="sm" @click="asignarRoles(item, row.index, 'personal')" v-b-tooltip.top title="Click para editar permisos">Permitir Roles</b-button>
                                    <div class="mb-1" v-if="item.roles">
                                      <span class="mr-2"
                                            v-for="value in item.roles.split(',')"
                                            :key="value"
                                            v-html="getNameRoles(value, 'personal')">
                                      </span>
                                    </div>
                                    <div v-else>
                                      <span class='badge badge-danger badge-module-permissions-custom'>No Configurado</span>
                                    </div>

                                    <div class="mb-1" v-if="item.modules_permissions_elements.length">
                                      <small class="text-muted">Esta vista contiene <b>{{item.modules_permissions_elements.length}} elementos</b> a los que se les puede denegar el permisos a los roles que la visualzan.</small>
                                      &nbsp;&nbsp;<b-button variant="outline-danger" size="sm" @click="openDenegarRoles(item, row.index, 'personal')" v-b-tooltip.top title="Click para editar permisos">Denegar Roles</b-button>
                                      <br>
                                      <div v-for="elements in item.modules_permissions_elements" :key="elements.id" class="mb-1">
                                        <span v-if="elements.roles">
                                          <span class="element-text-custom">{{elements.reference}}:</span>&nbsp;&nbsp;
                                          <span class="mb-1" v-for="rolDeny in elements.roles.split(',')" :key="rolDeny">
                                            <span class="mr-2" v-html="getNameRoles(rolDeny, 'personal')"></span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </b-list-group-item>

                                  <b-badge v-if="!filtroPersonal(row.item.modules_permissions).length" variant="warning" class="badge-module-permissions-custom p-5">No tiene vistas para este perfil</b-badge>
                                </b-list-group>

                              </b-card-text>
                            </b-tab>

                            <b-tab title="Cliente" v-if="isModuleActive('clientes')">
                              <b-card-text>

                                <b-list-group>
                                  <b-list-group-item href="#"
                                                    class="flex-column align-items-start"
                                                    v-for="item in filtroCliente(row.item.modules_permissions)"
                                                    :key="item.id">
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">
                                        ID:{{item.id}} - {{item.reference}}
                                      </h5>
                                      <b-badge variant="success" class="badge-module-permission-init-custom pull-right" v-if="item.init">Pantalla principal</b-badge>
                                    </div>
                                    <small class="text-muted">Roles que pueden visualizar la vista</small>
                                    &nbsp;&nbsp;<b-button variant="outline-info" size="sm" @click="asignarRoles(item, row.index, 'clientes')" v-b-tooltip.top title="Click para editar permisos">Permitir Roles</b-button>
                                    <div class="mb-1" v-if="item.roles">
                                      <span class="mr-2"
                                            v-for="value in item.roles.split(',')"
                                            :key="value"
                                            v-html="getNameRoles(value, 'clientes')">
                                      </span>
                                    </div>
                                    <div v-else>
                                      <span class='badge badge-danger badge-module-permissions-custom'>No Configurado</span>
                                    </div>

                                    <div class="mb-1" v-if="item.modules_permissions_elements.length">
                                      <small class="text-muted">Esta vista contiene <b>{{item.modules_permissions_elements.length}} elementos</b> a los que se les puede denegar el permisos a los roles que la visualzan.</small>
                                      &nbsp;&nbsp;<b-button variant="outline-danger" size="sm" @click="openDenegarRoles(item, row.index, 'clientes')" v-b-tooltip.top title="Click para editar permisos">Denegar Roles</b-button>
                                      <br>
                                      <div v-for="elements in item.modules_permissions_elements" :key="elements.id" class="mb-1">
                                        <span v-if="elements.roles">
                                          <span class="element-text-custom">{{elements.reference}}:</span>&nbsp;&nbsp;
                                          <span class="mb-1" v-for="rolDeny in elements.roles.split(',')" :key="rolDeny">
                                            <span class="mr-2" v-html="getNameRoles(rolDeny, 'clientes')"></span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </b-list-group-item>

                                  <b-badge v-if="!filtroCliente(row.item.modules_permissions).length" variant="warning" class="badge-module-permissions-custom p-5">No tiene vistas para este perfil</b-badge>
                                </b-list-group>

                              </b-card-text>
                            </b-tab>

                            <b-tab title="Proveedor" v-if="isModuleActive('proveedores')">
                              <b-card-text>

                                <b-list-group>
                                  <b-list-group-item href="#"
                                                    class="flex-column align-items-start"
                                                    v-for="item in filtroProveedor(row.item.modules_permissions)"
                                                    :key="item.id">
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">
                                        ID:{{item.id}} - {{item.reference}}
                                      </h5>
                                      <b-badge variant="success" class="badge-module-permission-init-custom pull-right" v-if="item.init">Pantalla principal</b-badge>
                                    </div>
                                    <small class="text-muted">Roles que pueden visualizar la vista</small>
                                    &nbsp;&nbsp;<b-button variant="outline-info" size="sm" @click="asignarRoles(item, row.index, 'proveedores')" v-b-tooltip.top title="Click para editar permisos">Permitir Roles</b-button>
                                    <div class="mb-1" v-if="item.roles">
                                      <span class="mr-2"
                                            v-for="value in item.roles.split(',')"
                                            :key="value"
                                            v-html="getNameRoles(value, 'proveedores')">
                                      </span>
                                    </div>
                                    <div v-else>
                                      <span class='badge badge-danger badge-module-permissions-custom'>No Configurado</span>
                                    </div>

                                    <div class="mb-1" v-if="item.modules_permissions_elements.length">
                                      <small class="text-muted">Esta vista contiene <b>{{item.modules_permissions_elements.length}} elementos</b> a los que se les puede denegar el permisos a los roles que la visualzan.</small>
                                      &nbsp;&nbsp;<b-button variant="outline-danger" size="sm" @click="openDenegarRoles(item, row.index, 'proveedores')" v-b-tooltip.top title="Click para editar permisos">Denegar Roles</b-button>
                                      <br>
                                      <div v-for="elements in item.modules_permissions_elements" :key="elements.id" class="mb-1">
                                        <span v-if="elements.roles">
                                          <span class="element-text-custom">{{elements.reference}}:</span>&nbsp;&nbsp;
                                          <span class="mb-1" v-for="rolDeny in elements.roles.split(',')" :key="rolDeny">
                                            <span class="mr-2" v-html="getNameRoles(rolDeny, 'proveedores')"></span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </b-list-group-item>

                                  <b-badge v-if="!filtroProveedor(row.item.modules_permissions).length" variant="warning" class="badge-module-permissions-custom p-5">No tiene vistas para este perfil</b-badge>
                                </b-list-group>

                              </b-card-text>
                            </b-tab>

                          </b-tabs>
                        </b-card-text>
                      </b-tab>

                      <b-tab title="Widgets" v-if="row.item.modules_widget.length">
                        <b-tabs pills card vertical>
                          <b-tab title="Staff" active v-if="isModuleActive('personal')">
                            <b-card-text>
                              <b-list-group>
                                <b-list-group-item href="#"
                                                  class="flex-column align-items-start"
                                                  v-for="item in filtroPersonal(row.item.modules_widget)"
                                                  :key="item.id">

                                  <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">
                                      ID:{{item.id}} -  {{item.title.toUpperCase()}}&nbsp;&nbsp;
                                      <small>{{item.description}}</small>
                                    </h5>
                                    <b-badge variant="success" class="badge-module-permission-init-custom pull-right">{{item.type}}</b-badge>
                                  </div>

                                  <small class="text-muted">Roles que NO pueden visualizar este widget</small>
                                  &nbsp;&nbsp;<b-button variant="outline-danger" size="sm" @click="denegarRolesWidget(item, row.index, 'personal')" v-b-tooltip.top title="Click para editar permisos">Denegar Roles</b-button>
                                  <div class="mb-1" v-if="item.roles">
                                    <span class="mr-2"
                                          v-for="value in item.roles.split(',')"
                                          :key="value"
                                          v-html="getNameRoles(value, 'personal')">
                                    </span>
                                  </div>
                                  <div v-else>
                                    <span class='badge badge-danger badge-module-permissions-custom'>No Configurado</span>
                                  </div>
                                </b-list-group-item>

                                <b-badge v-if="!filtroPersonal(row.item.modules_widget).length" variant="warning" class="badge-module-permissions-custom p-5">No tiene widgets para este perfil</b-badge>
                              </b-list-group>
                            </b-card-text>
                          </b-tab>
                          <b-tab title="Cliente" v-if="isModuleActive('clientes')">
                            <b-card-text>
                              <b-list-group>
                                <b-list-group-item href="#"
                                                  class="flex-column align-items-start"
                                                  v-for="item in filtroCliente(row.item.modules_widget)"
                                                  :key="item.id">

                                  <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">
                                      ID:{{item.id}} -  {{item.title.toUpperCase()}}&nbsp;&nbsp;
                                      <small>{{item.description}}</small>
                                    </h5>
                                    <b-badge variant="success" class="badge-module-permission-init-custom pull-right">{{item.type}}</b-badge>
                                  </div>

                                  <small class="text-muted">Roles que NO pueden visualizar este widget</small>
                                  &nbsp;&nbsp;<b-button variant="outline-danger" size="sm" @click="denegarRolesWidget(item, row.index, 'clientes')" v-b-tooltip.top title="Click para editar permisos">Denegar Roles</b-button>
                                  <div class="mb-1" v-if="item.roles">
                                    <span class="mr-2"
                                          v-for="value in item.roles.split(',')"
                                          :key="value"
                                          v-html="getNameRoles(value, 'clientes')">
                                    </span>
                                  </div>
                                  <div v-else>
                                    <span class='badge badge-danger badge-module-permissions-custom'>No Configurado</span>
                                  </div>
                                </b-list-group-item>

                                <b-badge v-if="!filtroCliente(row.item.modules_widget).length" variant="warning" class="badge-module-permissions-custom p-5">No tiene widgets para este perfil</b-badge>
                              </b-list-group>
                            </b-card-text>
                          </b-tab>
                          <b-tab title="Proveedor" v-if="isModuleActive('proveedores')">
                            <b-card-text>
                              <b-list-group>
                                <b-list-group-item href="#"
                                                  class="flex-column align-items-start"
                                                  v-for="item in filtroProveedor(row.item.modules_widget)"
                                                  :key="item.id">

                                  <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">
                                      ID:{{item.id}} -  {{item.title.toUpperCase()}}&nbsp;&nbsp;
                                      <small>{{item.description}}</small>
                                    </h5>
                                    <b-badge variant="success" class="badge-module-permission-init-custom pull-right">{{item.type}}</b-badge>
                                  </div>

                                  <small class="text-muted">Roles que NO pueden visualizar este widget</small>
                                  &nbsp;&nbsp;<b-button variant="outline-danger" size="sm" @click="denegarRolesWidget(item, row.index, 'proveedores')" v-b-tooltip.top title="Click para editar permisos">Denegar Roles</b-button>
                                  <div class="mb-1" v-if="item.roles">
                                    <span class="mr-2"
                                          v-for="value in item.roles.split(',')"
                                          :key="value"
                                          v-html="getNameRoles(value, 'proveedores')">
                                    </span>
                                  </div>
                                  <div v-else>
                                    <span class='badge badge-danger badge-module-permissions-custom'>No Configurado</span>
                                  </div>
                                </b-list-group-item>

                                <b-badge v-if="!filtroProveedor(row.item.modules_widget).length" variant="warning" class="badge-module-permissions-custom p-5">No tiene widgets para este perfil</b-badge>
                              </b-list-group>
                            </b-card-text>
                          </b-tab>
                        </b-tabs>
                      </b-tab>

                      <b-tab title="Parametros" v-if="row.item.modules_parameters.length">
                        <b-card-text>
                          <b-table class="mb-0"
                            responsive="sm"
                            head-variant="light"
                            :hover="true"
                            :small="true"
                            :fixed="false"
                            :items="tableParameters.items"
                            :fields="tableParameters.fields"
                            v-if="tableParameters.items.length">

                            <template v-slot:cell(value)="data">
                              <b-badge variant="success" class="badge-module-permissions-custom" v-if="data.item.value">Activado</b-badge>
                              <b-badge variant="danger" class="badge-module-permissions-custom" v-else>Inactivo</b-badge>
                            </template>

                            <template v-slot:cell(f_action)="data">
                              <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                <b-dropdown-item @click="configurateParametrsConfirm(data.item)" v-if="!data.item.value">
                                  <i class="fa fa-check-square-o" style="color:green"></i> Activar
                                </b-dropdown-item>
                                <b-dropdown-item @click="configurateParametrsConfirm(data.item)" v-if="data.item.value">
                                  <i class="fa fa-square-o" style="color:red"></i> Desactivar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>
                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

                        </b-card-text>
                      </b-tab>

                    </b-tabs>
                  </b-card>
                </template>

                <template v-slot:cell(name)="data">
                    {{data.item.name.toUpperCase()}}
                </template>

                <template v-slot:cell(description)="data">
                    <b>{{data.item.title.substring(data.item.title.indexOf(".") + 1)}}</b><br>
                    {{data.item.description}}
                </template>

                <template v-slot:cell(f_action)="row">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="openDetail(row.index)">
                      <i class="fa fa-cogs" style="color:blue"></i> Configurar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- PERMITIR -->
      <b-modal v-model="modal.form.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>
        <b-row>
          <b-col>
            <b-form-group label="Roles">
              <b-form-select v-model="crud.form.roles" :options="arr.roles" multiple :select-size="4"></b-form-select>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill mt-1" @click="crud.form.roles=[]">
                <i class="icon-refresh"></i>
                Borrar Selección
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">                  
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="configurate(modal.form.type)">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- DENEGAR -->
      <b-modal v-model="modal.formDeny.active"
               header-bg-variant="dark"
               header-text-variant="white"
               no-close-on-esc
               no-close-on-backdrop
               hide-header-close>

        <div slot="modal-header">
          {{this.modal.formDeny.title}}
        </div>
        <b-row>
          <b-col>

            <div role="tablist">
              <b-card no-body class="mb-1" v-for="(item, index) in arr.element" :key="item.id">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block href="#" v-b-toggle="'accordion-' + index" variant="secondary" class="text-left">
                    <b>Elemento:</b> {{item.reference}}
                  </b-button>
                </b-card-header>
                <b-collapse :id="'accordion-'+ index" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-alert variant="danger" :show="!arr.rolesDeny.length">
                      No se encontraron roles para denegar. <br>Primero asigne roles a la vista
                    </b-alert>
                    <b-form-group label="Roles" v-if="arr.rolesDeny.length">
                      <b-form-select v-model="item.roles" :options="arr.rolesDeny" multiple :select-size="4"></b-form-select>
                      <b-button type="button" variant="outline-dark" size="sm" class="btn-pill mt-1" @click="item.roles=[]">
                        <i class="icon-refresh"></i>
                        Borrar Selección
                      </b-button>
                    </b-form-group>
                    <b-form-group>
                      <button type="button" class="btn btn-dark pull-right" v-if="arr.rolesDeny.length" @click="guardarRolesDeny(item)">
                        Guardar
                      </button>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

          </b-col>
        </b-row>
        <template v-slot:modal-footer>
          <b-button type="button" variant="outline-secondary" @click="finishDenyRoles()">Terminar</b-button>
        </template>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'

  export default {
    data: () => {
      return {
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID'},
            {key: 'name', label: 'Módulo',},
            {key: 'description', label: 'Descripción'},
            {key: 'f_action', label: ''},
          ],
          rowSelected: null
        },
        tableParameters : {
          items: [],
          fields: [
            {key: 'id', label: 'ID'},
            {key: 'description', label: 'Descripción'},
            {key: 'value', label: 'Activado'},
            {key: 'f_action', label: ''},
          ],
        },
        modal: {
          form: {
            active: false,
            title: '',
            type: '',
          },
          formDeny: {
            active: false,
            title: '',
          }
        },
        crud: {
          form: {
            id: 0,
            module_id: 0,
            reference: '',
            roles: []
          }
        },
        arr: {
          roles: [],
          rolesPersonal: [],
          rolesClientes: [],
          rolesProveedores: [],
          modules: [],
          element: [],
          rolesDeny: [],
        }
      }
    },
    mounted() {
      this.cargar()
      this.cargarRoles()
    },
    methods: {
      cargar () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.modules = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true
            this.tableParameters.items = this.table.items[this.table.rowSelected].modules_parameters
          }
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      onRowSelected(item) {
        this.arr.modules.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        })
      },
      openDetail(index) {
        this.table.rowSelected = index
        this.cargar()
      },
      isModuleActive(value) {
        var status = false
        this.arr.modules.forEach(element=>{
          if(element.name==value) {
            status = true
          }
        })
        return status
      },
      filtroPersonal(row) {
        var arr = []
        row.forEach(element => {
          if(element.profile_id == Profiles.PERSONAL) {
            arr.push(element)
          }
        })
        return arr
      },
      filtroCliente(row) {
        var arr = []
        row.forEach(element => {
          if(element.profile_id == Profiles.CLIENTE) {
            arr.push(element)
          }
        })
        return arr
      },
      filtroProveedor(row) {
        var arr = []
        row.forEach(element => {
          if(element.profile_id == Profiles.PROVEEDOR) {
            arr.push(element)
          }
        })
        return arr
      },
      cargarRoles () {
        var result = serviceAPI.obtenerRoles()

        result.then((response) => {
          var data = response.data

          data.forEach(element => {
            if( element.profile_id == Profiles.PERSONAL ) {
              this.arr.rolesPersonal.push({ value: element.id, text: element.name })
            }
            if( element.profile_id == Profiles.CLIENTE ) {
              this.arr.rolesClientes.push({ value: element.id, text: element.name })
            }
            if( element.profile_id == Profiles.PROVEEDOR ) {
              this.arr.rolesProveedores.push({ value: element.id, text: element.name })
            }
          })
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },
      getNameRoles(item, tipo) {
        var name = ''

        if(tipo == "personal") {
          this.arr.rolesPersonal.forEach(element => {
            if(element.value == item) {
              name = element.text
            }
          });
        }
        if(tipo == "clientes") {
          this.arr.rolesClientes.forEach(element => {
            if(element.value == item) {
              name = element.text
            }
          });
        }
        if(tipo == "proveedores") {
          this.arr.rolesProveedores.forEach(element => {
            if(element.value == item) {
              name = element.text
            }
          });
        }

        if(name) {
          return "<span class='badge badge-secondary badge-module-permissions-custom'>" + name + "</span>"
        } else {
          return ""
        }
      },
      asignarRoles(item, row, tipo) {

        if(tipo == "personal") {
          this.arr.roles = this.arr.rolesPersonal
        }
        if(tipo == "clientes") {
          this.arr.roles = this.arr.rolesClientes
        }
        if(tipo == "proveedores") {
          this.arr.roles = this.arr.rolesProveedores
        }

        this.modal.form.active = true
        this.modal.form.title = "Asignación de Permisos --> Vista - " + item.reference.toUpperCase()
        this.modal.form.type = "vista"

        this.crud.form.id = item.id
        this.crud.form.module_id = item.module_id
        this.crud.form.reference = item.reference
        if(item.roles) {
          this.crud.form.roles = item.roles.split(',')
        } else {
          this.crud.form.roles = []
        }

        this.table.rowSelected = row
      },
      denegarRolesWidget(item, row, tipo) {

        if(tipo == "personal") {
          this.arr.roles = this.arr.rolesPersonal
        }
        if(tipo == "clientes") {
          this.arr.roles = this.arr.rolesClientes
        }
        if(tipo == "proveedores") {
          this.arr.roles = this.arr.rolesProveedores
        }

        this.modal.form.active = true
        this.modal.form.title = "Denegar Permisos --> Widget - " + item.title.toUpperCase()
        this.modal.form.type = "widget"

        this.crud.form.id = item.id
        this.crud.form.module_id = item.module_id
        this.crud.form.reference = item.reference
        if(item.roles) {
          this.crud.form.roles = item.roles.split(',')
        } else {
          this.crud.form.roles = []
        }

        this.table.rowSelected = row
      },
      configurate(type) {
        let loader = this.$loading.show();

        if(type=="vista") {
          var result = serviceAPI.configurar(this.crud.form);
        } else {
          if(type=="widget") {
            var result = serviceAPI.configurarWidget(this.crud.form);
          }
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.cargar()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      openDenegarRoles(item, row, tipo) {
        this.modal.formDeny.active = true
        this.modal.formDeny.title = "Denegar permisos --> Elementos de - " + item.reference.toUpperCase()

        if(tipo == "personal") {
          this.arr.rolesPersonal.forEach(element1 => {
            if(!item.roles) {
              item.roles = ''
            }
            item.roles.split(',').forEach(element2 => {
              if(element1.value == element2) {
                this.arr.rolesDeny.push({ value: element1.value, text: element1.text })
              }
            })
          })
        }
        if(tipo == "clientes") {
          this.arr.rolesClientes.forEach(element1 => {
            if(!item.roles) {
              item.roles = ''
            }
            item.roles.split(',').forEach(element2 => {
              if(element1.value == element2) {
                this.arr.rolesDeny.push({ value: element1.value, text: element1.text })
              }
            })
          })
        }
        if(tipo == "proveedores") {
          this.arr.rolesProveedores.forEach(element1 => {
            if(!item.roles) {
              item.roles = ''
            }
            item.roles.split(',').forEach(element2 => {
              if(element1.value == element2) {
                this.arr.rolesDeny.push({ value: element1.value, text: element1.text })
              }
            })
          })
        }

        item.modules_permissions_elements.forEach(element => {
          var rolElement = []
          if(element.roles) {
            rolElement = element.roles.split(',')
          }

          this.arr.element.push({
            id: element.id,
            reference: element.reference,
            roles: rolElement
          })
        })
      },
      guardarRolesDeny(item) {
        let loader = this.$loading.show();
        var data = { id: item.id, roles: item.roles}
        var result = serviceAPI.configurarRoleDeny(data);

        result.then((response) => {
          loader.hide()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      finishDenyRoles() {
        this.modal.formDeny.active = false
        this.modal.formDeny.title = ""

        this.arr.element = []
        this.arr.rolesDeny = []

        this.cargar()
      },
      configurateParametrsConfirm(item) {
        this.$bvModal.msgBoxConfirm('¿Desea cambiar el estado de este registro ID >> ' + item.id + '?', {
          title: 'Borrar Usuario',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            this.configurateParametrs(item)
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      configurateParametrs(item) {
        let loader = this.$loading.show();

        var data ={ id: item.id, value: !item.value}
        var result = serviceAPI.configurarParametro(data);

        result.then((response) => {
          var result1 = serviceAPI.obtenerParametros()

          result1.then((response1) => {
            var data = response1.data

            Session.setSessionParameters(data);
            loader.hide()
            this.cargar()
            this.$awn.success("Parametro configurado con éxito");
          })
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      }
    }
  }
</script>
<style>
  .badge-module-permissions-custom {
    font-size: 14px;
  }
  .badge-module-permission-init-custom {
    padding: 6px;
  }
  .element-text-custom {
    font-weight: 500
  }
</style>

